import Layout from 'components/layout';
import PageHeader from 'components/pageheader';
import Container from 'components/ui/container';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

export default function ProductsSingleTemplate({
    data,
}: {
    data: GatsbyTypes.BlogTemplateQuery;
}) {
    if (!data.mdx) {
        return (
            <Layout seo={{ title: 'Not translated' }}>
                <div>This pages has not been translated yet.</div>
            </Layout>
        );
    }
    return (
        <Layout
            seo={{
                title: data.mdx.frontmatter?.title as string,
                description: data.mdx.frontmatter?.description,
                image: data.mdx.frontmatter?.image?.publicURL,
            }}
        >
            <PageHeader
                title={data.mdx.frontmatter?.title as string}
                description={data.mdx.frontmatter?.description as string}
                image={
                    (data.mdx.frontmatter?.banner?.childImageSharp
                        ?.gatsbyImageData as unknown) as IGatsbyImageData
                }
            />
            <Container>
                <div className="page basepage">
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                </div>
            </Container>
        </Layout>
    );
}

export const query = graphql`
    query BlogTemplate($locale: String!, $slug: String!) {
        mdx(fields: { slug: { eq: $slug }, locale: { eq: $locale } }) {
            frontmatter {
                title
                description
                image {
                    publicURL
                }
                banner {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            body
        }
    }
`;
